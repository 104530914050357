import * as React from 'react';
import { Hero, Seo } from '../components';
import { Router } from '@reach/router';
import Ecp from '../components/ecp/ecp';
import { useEffect, useState } from 'react';
import { isBrowser } from '../utils';
import ProductRoute from '../components/product/product-route';

const EcpPage = ({ data }) => {
  const [isProduct, setIsProduct] = useState(false);
  useEffect(() => {
    if (isBrowser()) {
      const checkIsProduct =
        window.location.pathname.indexOf('product') >= 0 ? true : false;
      setIsProduct(checkIsProduct);
    }
  }, []);

  return (
    <>
      {!isProduct && (
        <Router basepath='/'>
          <ProductRoute path='/product/*' />
          <Ecp path='/*' />
        </Router>
      )}
    </>
  );
};

export default EcpPage;
