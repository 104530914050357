import * as React from 'react';
import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { isBrowser } from '../../utils';

const Ecp = () => {
  useEffect(() => {
    if (isBrowser()) {
      const accountCode = window.location.pathname.replace(/\//g, '');
      const goParam = window.location.search.match(/(\?|&)go\=([^&]*)/);
      const patientId = goParam ? goParam[2] : null;
      let redirectUrl = '/';

      const favoritesRedirectParam = window.location.search.match(
        /(\?|&)favoritesRedirect\=([^&]*)/
      );
      const favoritesRedirect = favoritesRedirectParam
        ? favoritesRedirectParam[2] === 'true'
        : false;

      if (accountCode.match(/^\d{4,5}-([a-zA-Z]|\d){4}$/)) {
        localStorage.setItem('accountCode', accountCode);
        redirectUrl = '/';

        if (patientId) {
          localStorage.setItem('patientId', patientId);
          redirectUrl = '/?frame-select=true';
        }

        if (favoritesRedirect) {
          redirectUrl = '/favorites';
        }
      } else {
        redirectUrl = '/403';
      }

      navigate(redirectUrl);
    }
  }, []);

  return <> </>;
};

export default Ecp;
